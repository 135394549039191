<template>
  <page :styleWrap='{background: "#fff"}'>
    <div class="changeNamePage">
      <header-bar>
        <div>修改群名称</div>
        <div slot='action' class="theme_c" @click='$router.back()'>取消</div>
      </header-bar>
      <input type="text" v-model='name' class="input" placeholder="请输入群名称">

      <button @click="getName" class="btn btn_radius theme_bg margin_auto changeNamePageBtn">确定</button>
    </div>
  </page>
</template>

<script>
import groupApi from '@/api/group'

export default {
  data: ()=> ({
    name: ''
  }),
  methods: {
    getName() {
      if (this.name) {
        groupApi.modifyGroupInfo({
          groupId: this.$route.query.groupId,
          groupName: this.name
        }).then(res => {
          if (res.data) {
            this.$toast(res.data.msg)
            const groupInfo = this.$global.getItem('groupInfo', true)
            if (groupInfo) {
              groupInfo.groupName = this.name
              this.$global.setItem('groupInfo', groupInfo, true)
            }
            setTimeout(() => {
              this.$router.back()
            }, 1000)
          }
        })
      } else {
        this.$toast('名称不能为空')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.changeNamePage {
  position: relative;
  .input {
    background: transparent;
    outline: none;
    border: none;
    padding: 15px;
    font-size: 16px;
    color: #2D2D2D;
    height: 40px;
    width: 100%;
  }
  .changeNamePageBtn {
    width: 86%;
    margin-top: 200px;
  }
}
</style>