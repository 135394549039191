import { render, staticRenderFns } from "./changeName.vue?vue&type=template&id=2842ccba&scoped=true"
import script from "./changeName.vue?vue&type=script&lang=js"
export * from "./changeName.vue?vue&type=script&lang=js"
import style0 from "./changeName.vue?vue&type=style&index=0&id=2842ccba&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2842ccba",
  null
  
)

export default component.exports